import React from "react";
import "./footer.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

import logo from "../../assets/logoCapPatrimoine.webp";
import { useDispatch } from "react-redux";
import { changePage } from "../../redux/changePage";

export default function Footer() {
  const dispatch = useDispatch();

  const handleClick = () => {
    document.querySelector(".legal-notice-wrapper").style.display = "block";
    setTimeout(() => {
      document.querySelector(".legal-notice-wrapper").style.opacity = 1;
    }, 10);
  };

  return (
    <div className="footer">
      <div className="footer-top">
        <div
          className="footer-logo-container"
          onClick={() => dispatch(changePage(0))}
        >
          <img
            className="footer-logo-container-img"
            src={logo}
            alt="CV-FC Gestion & Patrimoine"
          />
          <div className="footer-logo-container-name">
            <div>CV-FC Gestion & Patrimoine</div>
            <div>Conseil en gestion de patrimoine.</div>
          </div>
        </div>

        <div className="legal-notice-link" onClick={handleClick}>
          Mentions légales
        </div>

        <div className="contact-us" onClick={() => dispatch(changePage(4))}>
          Contactez-nous
        </div>

        <div>
          <a
            href="https://www.maxime-malfilatre.com/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="link to webmaster"
          >
            Site crée par Maxime Malfilâtre
          </a>
        </div>

        <div className="social-network">
          <a
            href="https://www.linkedin.com/in/christophe-vitu/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="link to linkedin"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>
      </div>

      <div className="footer-btm">
        <span>
          CV-FC GESTION & PATRIMOINE, SARL au capital social de 1 000 €, SIREN
          n°907510499, RNE 24/11/2021, NAF 6831Z, n° TVA intracommunautaire
          FR14907510499
        </span>
        <span>
          13 ALLEE DU CARRETEY 33610 CESTAS, Téléphone +33 6 81 76 67 18, Email
          cvconseilgestionpatrimoine@gmail.com
        </span>
        <span>
          Conseiller en investissements financiers, membre de l'association La
          Compagnie CIF , association professionnelle agréée par l'AMF.
        </span>
        <span>
          Courtier en assurance, enregistré à l'ORIAS (www.orias.fr) sous le
          n°22001355, membre de l'association La Compagnie IAS, association
          professionnelle agréée par l'ACPR.
        </span>
        <span>
          Activité de transaction sur immeubles et fonds de commerce, membre de
          l'association carte professionnelle n°CPI33012022000000038 délivrée
          par la CCI de Bordeaux, absence de garantie financière, non détention
          de fonds pour compte de tiers.
        </span>
        <span>
          La société CV-FC GESTION & PATRIMOINE dispose d'une couverture en
          Responsabilité Civile Professionnelle et d'une Garantie Financière
          suffisantes couvrant ses diverses activités, assurées auprès de AIG
          Europe SA 92400 Courbevoie. Ces couvertures sont notamment conformes
          aux exigences du Code Monétaire et Financier et du Code des
          Assurances.
        </span>
      </div>
    </div>
  );
}
